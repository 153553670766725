/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    h3: "h3",
    a: "a",
    div: "div",
    ul: "ul",
    li: "li",
    h4: "h4"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "atomic designについて読んで、考えていく"), "\n", React.createElement(_components.p, null, "http://atomicdesign.bradfrost.com/table-of-contents/"), "\n", React.createElement(_components.p, null, "おそらく2016年くらいに提唱された概念らしい"), "\n", React.createElement(_components.p, null, "reactが普及し始めたのを受けて考えたのだろうか"), "\n", React.createElement(_components.h3, {
    id: "atomic-design方法論",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#atomic-design%E6%96%B9%E6%B3%95%E8%AB%96",
    "aria-label": "atomic design方法論 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "atomic design方法論"), "\n", React.createElement(_components.p, null, "http://atomicdesign.bradfrost.com/outline/"), "\n", React.createElement(_components.p, null, "化学のアナロジーで定義している"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "atoms, molecules, organisms, templates, pagesの5つからなる"), "\n"), "\n", React.createElement(_components.h4, {
    id: "atoms原子",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#atoms%E5%8E%9F%E5%AD%90",
    "aria-label": "atoms原子 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "atoms(原子)"), "\n", React.createElement(_components.p, null, "これ以上分割できない単位"), "\n", React.createElement(_components.p, null, "分割されていると、簡単に共通語彙（文脈のようなもの？）を作れる"), "\n", React.createElement(_components.p, null, "抽象的かつ有用なものであることが大事"), "\n", React.createElement(_components.p, null, "コンポーネントとしてはボタンや、タグが該当するだろうか"), "\n", React.createElement(_components.h4, {
    id: "molecules分子",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#molecules%E5%88%86%E5%AD%90",
    "aria-label": "molecules分子 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "molecules(分子)"), "\n", React.createElement(_components.p, null, "原子がくっついたもの"), "\n", React.createElement(_components.p, null, "抽象的かつ文脈依存しないものであることが大事"), "\n", React.createElement(_components.p, null, "コンポーネントでいうとラベル、インプットフォーム、ボタンなどの原子が集合したものが分子、すなわち検索フォームになる"), "\n", React.createElement(_components.h4, {
    id: "organisms有機体",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#organisms%E6%9C%89%E6%A9%9F%E4%BD%93",
    "aria-label": "organisms有機体 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "organisms(有機体)"), "\n", React.createElement(_components.p, null, "分子や原子の集合体"), "\n", React.createElement(_components.p, null, "明確に別れており、役割はひとつ"), "\n", React.createElement(_components.p, null, "再利用可能なインターフェースを持ち、移植可能であるなどの特徴を持つ"), "\n", React.createElement(_components.p, null, "テーブルなどの単位だろう"), "\n", React.createElement(_components.h4, {
    id: "templates",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#templates",
    "aria-label": "templates permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "templates"), "\n", React.createElement(_components.p, null, "ここから化学とのアナロジーとは関係なくなってくる"), "\n", React.createElement(_components.p, null, "ページ単位での文書。コンテンツの構造に特化している"), "\n", React.createElement(_components.p, null, "コンテキストは、構成要素である分子や有機体から流れてくる"), "\n", React.createElement(_components.h4, {
    id: "pages",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#pages",
    "aria-label": "pages permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "pages"), "\n", React.createElement(_components.p, null, "templateのインスタンス、実際のコンテンツを流したもの"), "\n", React.createElement(_components.h3, {
    id: "感想",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E6%84%9F%E6%83%B3",
    "aria-label": "感想 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "感想"), "\n", React.createElement(_components.p, null, "es6以前のコードの組み方は全体のバランスをみて、cssやjsを組むことが多かったような気がする"), "\n", React.createElement(_components.p, null, "その場合はページ単位で見ていくので、サイトとしてトンマナを合わせるときに頭の中でレンダリングしていた"), "\n", React.createElement(_components.p, null, "es6以降ではatomic desingというか、コンポーネントの概念がでてきてから、部品単位で見た目や動きを考えればよくなったため見た目部分の共有がしやすくなったように思う"), "\n", React.createElement(_components.h3, {
    id: "参考",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#%E5%8F%82%E8%80%83",
    "aria-label": "参考 permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "参考"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "提唱者(Brad Frost氏)のサイト\nhttp://atomicdesign.bradfrost.com/"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "解説記事\nhttps://uxdaystokyo.com/articles/glossary/atomic-design/"), "\n"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
